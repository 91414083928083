<template>
    <div class="container">
        <div class="menu-card">
            <!-- 一级课程 -->
            <div class="card first">
                <div class="menu" v-for="item in subject" :key="item.volume">
                    {{ item.volume }}
                </div>
            </div>
            <!-- 二级课程 -->
            <div class="card second">
                <div class="unit-list" id="scrollId">
                    <button class="unit-item"
                        v-for="item in unitList"
                        :key="item.id"
                        :class="{'active': item.challenge || item.dictation }"
                        @click="browerWord(item)">
                        {{ item.catalog }} {{ item.finishChallenge }}
                    </button>
                </div>
            </div>
        </div>

        <!-- 操作选择 -->
        <unit-dialog :show.sync="showUnitDialog" :item="activeItem"></unit-dialog>
    </div>
</template>

<script>
import UnitDialog from "./unit-dialog.vue"

export default {
    components: {
        UnitDialog,
    },
    data (){
        return {
            activeMenu: '',
            activeItem: {},
            showUnitDialog: false,
            animation: null
        }
    },
    watch: {
        $route: {
            handler (val) {
                if (val.meta.scrollY) {
                    document.getElementById('scrollId').scrollBy(0, val.meta.scrollY);
                }
            },
            immediate: true
        }
    },
    computed: {
        subject () {
            return this.$store.getters.getSubjectList
        },

        unitList () {
            return this.$store.getters.getUnitList() || [];
        }
    },
    methods: {
        browerWord (item) {
            this.showUnitDialog = true;
            this.activeItem = item;
        }
    },

    beforeRouteLeave (to, from, next) {
        if (from.meta.keepAlive) {
            from.meta.scrollY = document.getElementById('scrollId').scrollTop;
        }
        next();
    }
}
</script>

<style lang="scss" scoped>
.container {
    position: relative;
    padding: 0;
    margin: 0;
    .lottie {
        position: absolute;
        left: -0%;
        bottom: -3%;
        width: 50%;
        min-width: 260px;
        height: auto;
        // animation: move 10s linear infinite;
    }

    .menu-card {
        position: relative;
        z-index: 2;
        height: 80vh;
        // width: var(--rap-menu-card-width);
        margin: 10vh auto;
        background: rgba($color: #ffffff, $alpha: .8);
        border-radius: 20px;
        backdrop-filter: blur(5px);
        box-shadow: 0 16px 80px rgba(17,0,102,.16);
        padding: 1.4rem;
        box-sizing: border-box;

        display: flex;
        flex-direction: var(--rap-menu-direction);

        .card {
            border-radius: 16px;
            padding: 10px;
            flex-grow: 1;
            box-sizing: border-box;
            max-height: var(--rap-menu-card-height);

            &.first {
                flex-shrink: 0;
                flex-grow: 0;
                width: var(--rap-menu-width);
                background: rgba($color: red, $alpha: .3);
                margin-right: var(--rap-menu-right);
                margin-bottom: var(--rap-menu-bottom);

                .menu {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    height: 100%;
                    font-weight: bold;
                    font-size: 34px;
                    text-align: center;
                    color: #fff;
                }
            }

            &.second {
                flex-grow: 1;
                background: rgba($color: orange, $alpha: .3);
                
                .unit-list {
                    // display: flex;
                    // flex-direction: row;
                    // flex-wrap: wrap;
                    display: grid;
                    grid-template-columns: repeat(var(--rap-menu-columns), 1fr);
                    gap: 10px;
                    max-height: 100%;
                    overflow: auto;
                    -webkit-overflow-scrolling: touch;
                    
                    .unit-item {
                        padding: .5rem .75rem;
                        height: var(--rap-menu-h);
                        min-width: 8rem;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        background: #fff;
                        // margin: 5px 10px;
                        border-radius: 10px;
                        border: 3px solid olive;
                        font-weight: 550;
                        font-size: 18px;

                        &.active {
                            color: white;
                            border-color: rgba($color: #EA5455, $alpha: .6);
                            background: rgba($color: #EA5455, $alpha: .6);
                        }
                    }
                }
            }
        }
    }
}

@keyframes move {
    0% {
        transform: translateX(-20%);
    }
    100% {
        transform: translateX(300%);
    }
}

// @media (max-width: 900px) {
//     .menu-card {
//         flex-direction: column !important;

//         .first, .second {
//             flex-grow: 1;
//             margin: 0 !important;
//             min-height: 100px;
//         }
//     }
// }

</style>