<template>
    <div class="unit-dialog" :class="{'show': show }">
        <div class="dialog-card">
            <!-- <div class="title-card">
                <div>{{ getUnitName.toUpperCase() || '' }}</div>
                <div>{{ subjectName }}</div>
            </div> -->
            <div class="title-card">
                <div class="unit-title">{{ getUnitName.toUpperCase() || '' }}</div>
                <div class="volume">{{ subjectName }}</div>
            </div>
            <div class="content-wrap">
                <div class="menu-item" @click="browerWord">单词列表</div>
                <div class="menu-item gap" @click="challenge">
                    <star-rate :accuracy="challengePercent" :width="20" :gap="12" type=""></star-rate>
                    连续挑战
                </div>
                <div class="menu-item gap" @click="dictateWord">
                    <star-rate :accuracy="dictationPercent" :width="20" :gap="12" type=""></star-rate>
                    单词听写
                </div>
                <div class="menu-item" @click="close">返回</div>
            </div>
        </div>
    </div>
</template>

<script>
import starRate from '@/components/star-rate.vue';

export default {
    props: {
        show: Boolean,
        item: Object,
    },
    components: {
        starRate
    },
    computed: {
        // 课程名称
        subjectName () {
            return this.$store.getters.getSubjectName(this.$store.state.curSubjectId);
        },
        // 单元名称
        getUnitName () {
            return this.$store.getters.getUnitName(this.$store.state.curSubjectId, this.item.id);
        },

        // 连续挑战百分比
        challengePercent () {
            if (this.item.id) {
                const data = this.$store.getters.getUnitItem(this.$store.state.curSubjectId, this.item.id);
                return data.challenge && data.challenge.percent ? (100 - Number(data.challenge && data.challenge.percent)) : 0;
            }
            return 0;
        },

        // 连续挑战百分比
        dictationPercent () {
            if (this.item.id) {
                const data = this.$store.getters.getUnitItem(this.$store.state.curSubjectId, this.item.id);
                return data.dictation && data.dictation.percent ? (100 - Number(data.dictation && data.dictation.percent)) : 0;
            }
            return 0;
        }

    },
    methods: {
        // 关闭
        close () {
            this.$emit('update:show', false);
        },

        // 连线挑战
        challenge () {
            this.close();
            setTimeout(() => {
                this.$router.push({
                    path: '/game/' + this.$store.state.curSubjectId + '/' + this.item.id
                })
            }, 200)
        },

        // 单词列表
        browerWord () {
            this.close();
            setTimeout(() => {
                this.$router.push({
                    path: '/word/' + this.$store.state.curSubjectId + '/' + this.item.id
                })
            }, 200)
        },

        // 单词列表
        dictateWord () {
            this.close();
            setTimeout(() => {
                this.$router.push({
                    path: '/dictation/' + this.$store.state.curSubjectId + '/' + this.item.id
                })
            }, 200)
        },
    }
}
</script>

<style lang="scss" scoped>
.unit-dialog {
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    z-index: 3;
    background: rgba($color: #1F254D, $alpha: .75);
    backdrop-filter: blur(10px);
    opacity: 0;
    transform: translateY(100%);
    transition: all .2s ease-out;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
    
    &.show {
        opacity: 1;
        transform: translateY(0);
        transition: all .5s ease-in-out;
        // animation: slide-up .5s ease-in-out forwards;
    }

    .dialog-card {
        width: 100%;
        min-height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        color: rgba($color: #ffffff, $alpha: 1);
        padding: 20px 0;
        box-sizing: border-box;
        .title-card {
            display: flex;
            justify-content: center;
            flex-direction: column;
            align-items: center;
            width: 100%;
            padding-bottom: 30px;
            border-bottom: 2px solid rgba($color: #ffffff, $alpha: 1);

            .unit-title {
                font-size: 38px;
                font-weight: bold;
                margin-bottom: 10px;
            }

            .volume {
                font-size: 24px;
                font-weight: 450;
            }
        }

        .content-wrap {
            // padding: 20px 0;
            .menu-item {
                color: rgba(228, 219, 57, 1);
                font-weight: 700;
                font-size: 34px;
                padding: 20px 0;
                text-align: center;

                &.gap {
                    padding: 10px 0;
                }
            }
        }
    }
}

@keyframes slide-up {
    0% {
        transform: translateY(100%);
        opacity: 0;
    }
    100% {
        transform: translateY(0);
        opacity: 1;
    }
}
</style>